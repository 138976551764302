#features {
  margin-top: 72px;
  @include media-breakpoint-up(sm){
    margin-top: 96px;
  }
  @include media-breakpoint-up(md){
    margin-top: 160px;
  }

  .carousel {
    margin-top: 16px;
    @include media-breakpoint-up(sm){
      margin-top: 32px;
    }
    @include media-breakpoint-up(md){
      margin-top: 16px;
    }
  }

  .accordionContainer {
    width:100%;
    order: 1;
    @include media-breakpoint-up(md){
      width: 50%;
      order: 0;
    }
  }

  /* Style for the accordion carousel container */
  .accordion-carousel {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(md){
      flex-direction: row;
    }
  }

  /* Style for the accordion items */
  .accordion-item {
    cursor: pointer;
    margin-top: 24px;
    display:none;

    @include media-breakpoint-up(md){
      display:block;
    }

    &.active {
      display: block;
      @include media-breakpoint-up(md){
        display:block;
      }
      .accordion-headline {
        color: var(--textColor)
      }
    }
  }

  /* Style for the accordion headlines */
  .accordion-headline {
    font-size: 32px;
    line-height: 38px;
    color: var(--iconColor);
    transition: all 0.3s;

    &.pink {
      @media (hover: hover) {
        &:hover {
          color: var(--requirementsColor);
        }
      }
    }

    &.yellow {
      @media (hover: hover) {
        &:hover {
          color: var(--downloadColor);
        }
      }
    }

    &.orange {
      @media (hover: hover) {
        &:hover {
          color: var(--supportColor);
        }
      }
    }

    &.blue {
      @media (hover: hover) {
        &:hover {
          color: var(--faqColor);
        }
      }
    }
  }

  /* Style for the accordion descriptions */
  .accordion-description {
    margin-top: 24px;
    font-size: 24px;
    line-height: 30px;
  }

  /* Style for the photo container */
  #photoContainer {
    position: relative;
    width: 100%;
    order: 0;
    background-position: center;
    @include media-breakpoint-up(sm){
      height: 445px;
    }
    @include media-breakpoint-up(md){
      width: 50%;
      order: 1;
      margin-left: 32px;
      height:auto;
    }
    background-color: var(--bgColor);
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.4s;
    @supports (-webkit-touch-callout: none) {
      transition: none;
    }
    
    &.ui-01 {
      background-image: url('../images/feature-01.svg');
    }

    &.ui-02 {
      background-image: url('../images/feature-02.svg');
    }

    &.ui-03 {
      background-image: url('../images/feature-03.svg');
    }

    &.ui-04 {
      background-image: url('../images/feature-04.svg');
    }
  }

  /* Style for the photos */
  .photo {
    width: 80%;
    @include media-breakpoint-up(sm){
      width: 410px;
    }
    @include media-breakpoint-up(md){
      width: 80%;
      min-width: 410px;
    }
    margin: 0 auto;
  }

  .left-arrow, .right-arrow {
    box-sizing: border-box;
    position: absolute;
    border: 2px solid var(--textColor);
    border-radius: 25px;
    width: 56px;
    height: 56px;
    padding: 15px;
    top: 50%;
    background-color: none;
    color: var(--textColor);
    transform: translateY(-50%);
    cursor: pointer;
    transition: all 0.4s;
    @include media-breakpoint-up(md){
      display:none;
    }

    div {
      width: 24px;
      height: 24px;
      -webkit-mask-size: cover;
      mask-size: cover;
      mask-position: center;
      background-color: var(--textColor);
    }

    &.active {
      background-color: var(--textColor);
      .left, .right {
        background-color: var(--bgColor);
        transition: all 0.5s;
      }
    }

    @media (hover: hover) {
      &:hover {
        background-color: var(--textColor);
        .left, .right {
          background-color: var(--bgColor);
          transition: all 0.5s;
        }
      }
    }
  }

  .left-arrow {
    left: 2%;
    @include media-breakpoint-up(sm){
      left: 5%;
    }

    .left {
      -webkit-mask-image: url('../images/left.svg');
      mask-image: url('../images/left.svg');
    }
  }

  .right-arrow {
    right: 2%;
    @include media-breakpoint-up(sm){
      right: 5%;
    }

    .right {
      -webkit-mask-image: url('../images/right.svg');
      mask-image: url('../images/right.svg');
    }
  }

  .timer-background {
    display:none;
    @include media-breakpoint-up(md){
      display:block;
    }
    margin-top:24px;
    height: 2px;
    width: 100%;
    background-color: var(--timerBackground);
  }

  .responsiveTimer {
    display: flex;
    flex-direction: row;
    gap: 7.5px;
    @include media-breakpoint-up(md){
      display:none;
    }
    .timer-background {
      display: flex;
      width: 25%;
    }
  }

  /* Style for the timer bar */
  .timer-bar {
    width: 20%;
    height: 2px;
    background-color: var(--textColor);
    transition: linear 1s;
  }
}
