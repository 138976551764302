#requirements {
  margin-top: 72px;
  font-family: 'Satoshi-Medium';
  font-size: 20px;
  line-height: 24px;
  @include media-breakpoint-up(sm){
    margin-top: 96px;
    font-size: 24px;
    line-height: 30px;
  }
  @include media-breakpoint-up(md){
    margin-top: 104px;
  }

  .slant {
    display: inline-block;
    transform: rotate(-4.5deg);
  }

  .asteriskNote {
    margin-top: 27px;
    font-size: 16px;
    line-height: 20px;
    @include media-breakpoint-up(sm){
      font-size: 20px;
      line-height: 24px;
    }
  }

  .asteriskContainer {
    margin-left:8px;
  }

  .asterisk {
    width: 16.5px;
    height: 16px;
  }

  .table {
    margin-top: 40px;
    @include media-breakpoint-up(sm){
      margin-top: 32px;
    }
    @include media-breakpoint-up(md){
      margin-top: 56px;
    }
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
  }
  .table-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 24px 0px;
    border-bottom: 2px solid var(--textColor);
    align-self: stretch;
    justify-content: space-between;
  }
  .table-cell {
    width: 50%;
    padding-right: 20px;
    @include media-breakpoint-up(sm){
      width: 33%;
    }
    &.part {
      display: none;
      @include media-breakpoint-up(sm){
        display: flex;
      }
    }
  }
}
