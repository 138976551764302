#top {
  height: 100%;
  @include media-breakpoint-up(sm){
  }
  @include media-breakpoint-up(md){
    height: 1381px;
  }

  .headerTextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 25px;
    margin-bottom: 45px;

    @include media-breakpoint-up(sm){
      padding: 0px 48px;
    }
    
    .headline-primary {
      font-size: 48px;
      line-height: 50px;
      @include media-breakpoint-up(sm){
        font-size: 76px;
        line-height: 90px;
      }
      @include media-breakpoint-up(md){
        font-size: 96px;
        line-height: 110px;
      }
      text-align: center;
    }

    .headline-secondary {
      font-size: 48px;
      line-height: 58px;
      @include media-breakpoint-up(sm){
        font-size: 76px;
        line-height: 90px;
      }
      @include media-breakpoint-up(md){
        font-size: 96px;
        line-height: 96px;
      }
      text-align: center;
      letter-spacing: -0.01em;
    }

    .header {
      font-size: 20px;
      line-height: 24px;
      margin-top: 24px;
      @include media-breakpoint-up(sm){
        font-size: 24px;
        line-height: 30px;
        margin-top: 32px;
      }
      @include media-breakpoint-up(md){
        font-size: 32px;
        line-height: 42px;
        margin-top: 48px;
        padding: 0px 70px;
      }
      text-align: center;
    }
  }

  .graphic {
    background-image: var(--headerImage);
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 316px;
    @include media-breakpoint-up(sm){
      padding-top: 0px;
      height: 555px;
    }
    @include media-breakpoint-up(md){
      padding-top: 40px;
      height: 959px;
      background-position: center;
      // background-size: cover;
    }
    @include media-breakpoint-up(xlg){
      background-size: contain;
    }

    .application {
      // margin-top: 83px;
      width: 100%;
      height: 100%;
      background-position: center;
      @include media-breakpoint-up(sm){
        background-position: center top;
        // width: 100%;
      }
      @include media-breakpoint-up(md){
        background-position: center;
        width: 100%;
        height: 100%;
        max-width: 1300px;
        max-height: 822px;
      }
      background-image: var(--applicationImage);
      background-size: contain;
      background-repeat: no-repeat;
      margin: 0 auto;
      transition: all 0.4s;
      position: relative;

      .comingSoon {
        background-image: url('../images/comingSoon.png');
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;

        height: 92.39px;
        width: 134.92px;
        top: 1%;
        right: 10px;
        @include media-breakpoint-up(sm){
          height: 113px;
          width: 165.92px;
          top: -12px;
          right: 3%;
        }
        @include media-breakpoint-up(md){
          height: 190.39px;
          width: 285.92px;
          top: 2%;
          right: 0;
        }
      }
    }
  }
}
