/**
 * @license
 *
 * Font Family: Satoshi
 * Designed by: Deni Anggara
 * URL: https://www.fontshare.com/fonts/satoshi
 * © 2022 Indian Type Foundry
 *
 * Font Styles:
 * Satoshi Variable(Variable font)
 * Satoshi Variable Italic(Variable font)
 * Satoshi Light
 * Satoshi Light Italic
 * Satoshi Regular
 * Satoshi Italic
 * Satoshi Medium
 * Satoshi Medium Italic
 * Satoshi Bold
 * Satoshi Bold Italic
 * Satoshi Black
 * Satoshi Black Italic
 *
*/


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 900.0;
*
* available axes:

* 'wght' (range from 300.0 to 900.0)

*/

@font-face {
  font-family: 'Satoshi-Medium';
  src: url('../fonts/Satoshi-Medium.woff2') format('woff2'),
       url('../fonts/Satoshi-Medium.woff') format('woff'),
       url('../fonts/Satoshi-Medium.ttf') format('truetype');
       font-weight: 500;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'Satoshi-Bold';
  src: url('../fonts/Satoshi-Bold.woff2') format('woff2'),
       url('../fonts/Satoshi-Bold.woff') format('woff'),
       url('../fonts/Satoshi-Bold.ttf') format('truetype');
       font-weight: 700;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'DynaPuff';
  src: url('../fonts/DynaPuff-Medium.ttf') format('truetype');
       font-weight: 500;
       font-display: swap;
       font-style: normal;
}
