@import 'abstracts/variables';

@import 'mixins/breakpoints';

@import 'base/reset';
@import 'base/typography';

@import 'layout/header';
@import 'layout/main/main';
@import 'layout/main/top';
@import 'layout/main/statement';
@import 'layout/main/requirements';
@import 'layout/main/features';
@import 'layout/main/download';
@import 'layout/main/support';
@import 'layout/main/terms';
@import 'layout/main/faq';
@import 'layout/footer';
@import 'layout/cookie-banner';

body {
  background-color: var(--bgColor);
  color: var(--textColor);
  font-family: 'Satoshi-Medium';
  transition: all 0.4s;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.link {
  color: var(--textColor);
  transition: all 0.4s;
  @media (hover: hover) {
    &:hover {
      color: var(--iconColor);
    }
  }
}

.button {
  box-sizing: border-box;
  border: 2px solid var(--textColor);
  border-radius: 25px;
  padding: 13px 24px;
  background-color: none;
  color: var(--textColor);
  cursor: pointer;
  text-decoration: none;
  transition: all 0.4s;

  &.active {
    background-color: var(--textColor);
    color: var(--bgColor);
  }

  @media (hover: hover) {
    &:hover {
      background-color: var(--textColor);
      color: var(--bgColor);
    }
  }

  &.small {
    padding: 8px 18px;
    border-radius: 18px;
  }
}