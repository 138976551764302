#terms {
  padding: 0px 16px;
  @include media-breakpoint-up(sm){
    padding: 0px 24px;
  }
  @include media-breakpoint-up(md){
    padding: 0px 48px;
  }

  h3 {
    margin-bottom: 12px;
    font-size: 32px;
    line-height: 38px;
    @include media-breakpoint-up(sm){
      font-size: 48px;
      line-height: 58px;
    }
    @include media-breakpoint-up(md){
      font-size: 60px;
      line-height: 72px;
    }
  }

  ul {
    list-style: disc;
    margin-left: 15px;
    margin-top: 12px;
    line-height: 20px;
  }

  h4 {
    margin-top: 40px;
    font-size: 24px;
    line-height: 30px;
  }

  .lastUpdated {
    color: var(--iconColor);
    font-size: 16px;
    margin-bottom: 32px;
  }

  p {
    margin-top: 12px;
    font-size: 16px;
    line-height: 20px;

    &.declaration {
      margin-top: 50px;
    }

    span {
      background-size: cover;
      display: inline-block;
      background-repeat: no-repeat;
      margin-bottom: -4px;
    }
  }
}
