#statement {
  margin-top: 56px;
  @include media-breakpoint-up(sm){
    margin-top: 40px;
  }
  @include media-breakpoint-up(md){
    margin-top: 18px;
  }

  p {
    font-size: 32px;
    line-height: 38px;
    @include media-breakpoint-up(sm){
      font-size: 48px;
      line-height: 58px;
    }
    @include media-breakpoint-up(md){
      font-size: 76px;
      line-height: 90px;
    }
  }

  .learn {
    font-size: 24px;
    line-height: 30px;
    margin-top: 32px;
    @include media-breakpoint-up(sm){
      font-size: 32px;
      line-height: 38px;
      margin-top: 40px;
    }
    @include media-breakpoint-up(md){
      margin-top: 64px;
    }
  }
}
