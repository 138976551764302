main {
  padding-top: 48px;
  @include media-breakpoint-up(sm){
    padding-top: 64px;
  }
  @include media-breakpoint-up(md){
    padding-top: 94px;
  }

  .dynapuff {
    font-family: 'DynaPuff';
  }

  section:not(:first-child) {
    padding: 0px 16px;
    @include media-breakpoint-up(sm){
      padding: 0px 24px;
    }
    @include media-breakpoint-up(md){
      padding: 0px 48px;
    }
  }

  section {
    h3 {
      font-size: 48px;
      line-height: 58px;
      @include media-breakpoint-up(sm){
        font-size: 60px;
        line-height: 72px;
      }
      @include media-breakpoint-up(md){
        font-size: 96px;
        line-height: 110px;
      }
    }
  }
}