.cookie-banner {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 70px);
  max-width: 640px;
  background-color: var(--bgColor);
  border: 2px solid var(--textColor);
  border-radius: 24px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @include media-breakpoint-up(sm) {
    bottom: 30px;
    max-width: 800px;
    flex-direction: row;
    justify-content: space-between;
  }

  .cookie-content {
    display: flex;
    align-items: center;
    gap: 16px;

    .cookie-icon {
      width: 40px;
      height: 40px;
      min-width: 40px;
      background-image: var(--cookieIconImage);
      background-size: contain;
      background-repeat: no-repeat;
    }

    p {
      font-size: 16px;
      line-height: 1.5;
      margin: 0;
    }
  }

  .cookie-actions {
    display: flex;
    gap: 12px;
  }
}