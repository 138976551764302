#faq {
  margin-top: 72px;
  @include media-breakpoint-up(sm){
    margin-top: 96px;
  }
  @include media-breakpoint-up(md){
    margin-top: 120px;
  }

  .accordion {
    padding: 16px 0px;
    @include media-breakpoint-up(sm){
      padding: 24px 0px;
    }
    @include media-breakpoint-up(md){
      padding: 32px 0px;
    }
    display:flex;
    color: var(--textColor);
    background-color: transparent;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    font-size: 24px;
    line-height: 30px;
    font-family: 'Satoshi-Medium';
    font-style: normal;
    @include media-breakpoint-up(sm){
      font-size: 32px;
      line-height: 38px;
    }
    @include media-breakpoint-up(md){
      font-size: 40px;
      line-height: 48px;
      font-weight: 500;
    }
    border-bottom: 2px solid var(--textColor);
    transition: all 0.4s;

    .question {
      margin-left: 24px;
    }

    .arrow {
      margin-left: 10px;
    }

    .spacer {
      flex-grow: 1;
    }

    &.pink {
      @media (hover: hover) {
        &:hover {
          color: $pink1;
        }
      }
    }

    &.yellow {
      @media (hover: hover) {
        &:hover {
          color: $yellow;
        }
      }
    }

    &.orange {
      @media (hover: hover) {
        &:hover {
          color: $orange;
        }
      }
    }

    &.blue {
      @media (hover: hover) {
        &:hover {
          color: $blue;
        }
      }
    }

    &.purple {
      @media (hover: hover) {
        &:hover {
          color: $purple;
        }
      }
    }
  }
  .active {
    border-bottom: none;
  }

  .panel {
    display: none;
    background-color: none;
    overflow: hidden;
    font-size: 24px;
    line-height: 30px;
    @include media-breakpoint-up(xs){
      font-size: 20px;
      line-height: 24px;
    }
    padding-bottom: 30px;
    border-bottom: 2px solid var(--textColor);

    ol {
      margin-top: 15px;
      li {
        list-style: inside;
      }
    }
  }
}
