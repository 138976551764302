#support {
  margin-top: 72px;
  @include media-breakpoint-up(sm){
    margin-top: 96px;
  }
  @include media-breakpoint-up(md){
    margin-top: 120px;
  }
  h3 {
    margin-bottom: 32px;
  }
  p {
    font-size: 24px;
    line-height: 32px;
    @include media-breakpoint-up(sm){
      font-size: 40px;
      line-height: 50px;
    }
    @include media-breakpoint-up(md){
      font-size: 48px;
      line-height: 60px;
    }

    span {
      background-size: cover;
      display: inline-block;
      background-repeat: no-repeat;
      margin-bottom: -4px;

      &.ethereumFoundation {
        background-image: var(--ethereumFoundationImage);
        width: 15.71px;
        height: 26px;
        @include media-breakpoint-up(sm){
          width: 24.17px;
          height: 40px;
        }
        @include media-breakpoint-up(md){
          width: 29px;
          height: 48px;
        }
      }

      &.gitcoinGrants {
        background-image: var(--gitcoinGrantsImage);
        width: 21.12px;
        height: 26px;
        @include media-breakpoint-up(sm){
          width: 32.5px;
          height: 40px;
        }
        @include media-breakpoint-up(md){
          width: 39px;
          height: 48px;
        }
      }

      &.cityDao {
        background-image: var(--cityDaoImage);
        width: 25.46px;
        height: 26px;
        @include media-breakpoint-up(sm){
          width: 39.17px;
          height: 40px;
        }
        @include media-breakpoint-up(md){
          width: 47px;
          height: 48px;
        }
      }

      &.ethStaker {
        background-image: var(--ethStakerImage);
        width: 16.73px;
        height: 26px;
        @include media-breakpoint-up(sm){
          width: 25.73px;
          height: 40px;
        }
        @include media-breakpoint-up(md){
          width: 30.88px;
          height: 48px;
        }
      }

      &.optimism {
        background-image: var(--optimismImage);
        width: 26px;
        height: 26px;
        @include media-breakpoint-up(sm){
          width: 40px;
          height: 40px;
        }
        @include media-breakpoint-up(md){
          width: 48px;
          height: 48px;
        }
      }

      &.allNodes {
        background-image: var(--allnodesImage);
        width: 26px;
        height: 26px;
        @include media-breakpoint-up(sm){
          width: 40px;
          height: 40px;
        }
        @include media-breakpoint-up(md){
          width: 48px;
          height: 48px;
        }
      }

      &.octant {
        background-image: var(--octantImage);
        width: 26px;
        height: 26px;
        @include media-breakpoint-up(sm){
          width: 40px;
          height: 40px;
        }
        @include media-breakpoint-up(md){
          width: 48px;
          height: 48px;
        }
      }
    }
  }
}
